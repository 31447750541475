/* width */
body::-webkit-scrollbar {
  width: 8px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #efeded; 
  border-radius: 4px;
}
 
/* Handle */
body::-webkit-scrollbar-thumb {
  background: #de2d5c; 
  border-radius: 10px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

.App {
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 3000px; /* Adjust the width as needed */
  margin: 0 auto; /* Center the content horizontally */
  padding: 20px; /* Add some padding for spacing */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-size: 54px;
  font-weight: bolder;
  text-align: left;
  letter-spacing: -1px;
  color: #242424;
}
h1 span:first-child {
  color:rgb(25, 39, 235);
}
h1 span:nth-child(2) {
  color:rgb(255, 20, 83);
}
h1 span:nth-child(3) {
  color:rgb(255, 146, 20);
}
h1 span:last-child {
  color:rgb(36, 211, 255);
}

/* Form styles */
form {
  margin: 20px auto;
  margin-top: 2.5rem;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
  margin-top: 2.5rem;
  gap: 0.5rem;
}

textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  width: calc(100% - 200px - 1rem);
  padding: 1.5rem 2.5rem;
  border-radius: 1.5rem;
  font-size: 1rem;
  resize: none;
  min-height: 125px;
  font-family: arial, 'sans-serif';
  background: #fdfdfd;
}

button {
  background-color: #de2d5c;
  border: none;
  border-radius: 1.5rem;
  color: #fff;
  cursor: pointer;
  padding: 1.5rem 3.5rem;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: 1px;
  width: 200px;
  height: auto;
}

button:hover {
  background-color: #811c37; /* Red/Pink color */
}

.response {
  padding: 2rem; /* Add some general padding */
  max-width: 900px; /* Control max width for alignment */
  margin-left: auto; /* Right-align the entire block */
}

.response-text {
  white-space: pre-wrap;
}

h2 {
  color: #242424;
  text-align: left;
  font-weight: 600;
}

ul {
  text-align: left;
  color: #606060;
  font-weight: 500;
  list-style-type: none;
  padding: 0;
}

small {
  width: 100%;
  margin-right: auto;
  display: block;
  text-align: left;
  color: #959595;
  font-weight: 500;
  letter-spacing: 1px;
}

/* Add formatting for h3 elements */
h3 {
  color: #FF1454; /* Blue color */
  font-size: 24px; /* Adjust the font size as needed */
  margin: 10px 0; /* Add margin for spacing */
}

/* Add formatting for li elements */
li {
  font-size: 21px; 
  margin-bottom: 5px;
  list-style-type: circle;
}

/* You can also add additional styling as needed for these elements */
.start {
  color: #1928EA; /* Blue for 'Start' */
}

.scale {
  color: #FF1454; /* Redish pink for 'Scale' */
}

.exit {
  color: #FE9315; /* Orange for 'Exit' */
}

.repeat {
  color: #24D5FF; /* Light blue for 'Repeat' */
}

/* Media query for responsive design */
@media (max-width: 600px) {
  .App {
    padding: 10px 20px 20px 10px;
  }
  li {
    font-size: 21px;
  }
  form {
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 1.15rem;
  }
  li {
    font-size: 18px;
  }
  textarea {
    padding: 1rem;
    width: calc(100% - 1rem - 10px);
  }
  small {
    font-size: 12px;
  }
}

.question-button {
  background: none;
  border: none;
  text-align: left;
  color: #000000;
  padding: 10px 0;
  font-size: 16px;
  cursor: pointer;
  width: 70%; /* Adjust as needed */
  text-align: left;
  box-shadow: none;
  font-weight: 300;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.question-button:hover {
  background-color: #ffffff;
  font-weight: 550;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.question-button:active {
  position: relative;
  top: 1px;
  left: 1px;
  box-shadow: none;
}

.icon-button {
  display: flex;
  justify-content: flex-start;
  background: none;
  border: none;
  color: rgb(221, 221, 221); /* Change default color to grey */
  cursor: pointer;
  padding: 4px; /* Decrease padding to 4px */
  font-size: 27px; /* Decrease font size to 18px */
  width: 44px; /* Adjust width to accommodate padding */
  height: 44px; /* Adjust height to accommodate padding */
  margin-right: 4px; /* Increase space between icons */
  box-shadow: none;
}


.icon-buttons .icon-button:hover {
  background-color: #ffffff;
  align-self: flex-start;


}

.icon-button:hover {
  color: rgb(231, 255, 154); /* Change color to black on hover */
}


.loading-animation {
  animation: fadeInOut 12s infinite;
}

/* IconButton container and label styles for hover effect */
.icon-button-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-label {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px; /* Distance from icon */
  font-size: 12px;
  white-space: nowrap;
}

.icon-button-container:hover .icon-label {
  visibility: visible;
}
.video-table {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.video-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
}

.video-thumbnail {
  width: 250px; /* Set thumbnail width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Add shadow */
}

.video-title {
  text-align: center;
  width: 250px; /* Match thumbnail width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333;
}


.resources-header {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #FF1454;
}

/* Unified styling for both Articles and Videos */
.resources-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.resource-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.resource-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #333;
  width: 100%;
}

.resource-image {
  width: 80px;  /* Consistent size for both article and video images */
  height: 80px;
  margin-right: 15px;
  object-fit: contain;
  border-radius: 5px; /* Optional for rounded corners */
}

.resource-type {
  font-size: 0.9em;
  color: #FF1454; /* Color for "Article" or "Video" */
  margin-bottom: 5px;
  font-weight: bold;
}

.resource-title {
  font-size: 1.2em;
  font-weight: bold;
}

.resource-content {
  display: flex;
  flex-direction: column;
}

/* Optional: Add hover effect */
.resource-link:hover .resource-title {
  text-decoration: underline;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .resource-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .resource-image {
    margin-bottom: 10px;
  }
}

/* Style for the AI says heading */
.ai-says {
  font-size: 2rem;
  font-weight: bold;
  color: #FF1454; /* Custom pinkish-red color */
  text-align: left; /* Left align the heading */
  margin-left: 18rem;
  margin-bottom: -4rem;
}

/* Style for the AI content box */
.ai-content {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style for the response text */
.response-text {
  font-size: 1.2rem;
  line-height: 1.4;
  color: #333;
  text-align: left; /* Left align the text */
}

/* Style for the resource header */
.resource-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #000; /* Set color to black */
  text-align: left;
  margin-left: 2rem; /* Add left margin to align */
}

/* Style for the resources list */
.resources-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style for each resource item */
.resource-item {
  display: flex;
  justify-content: space-between; /* Space between content and thumbnail */
  background-color: #f2f2f2;
  padding: 0.8rem 1rem;
  border-radius: 8px;
  margin-bottom: 0.8rem;
  transition: background-color 0.3s ease;
}

.resource-item:hover {
  background-color: #e0e0e0;
}

/* Style for the resource link */
.resource-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  justify-content: space-between; /* Ensure content and thumbnail stay separate */
  width: 100%;
}

/* Style for the resource image - aligned to the right */
/* Style for the resource image */
.resource-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 0; /* Keep rectangular */
  margin-left: 0; /* No right margin */
}

/* Style for the resource content (text) */
.resource-content {
  display: flex;
  flex-direction: column;
}

/* Style for the resource type (Article, Video, etc.) */
.resource-type {
  font-size: 1.1rem;
  color: #FF1454;
  margin-bottom: 0.6rem;
  text-align: left;
  text-decoration: underline;
  font-weight: normal;
}

/* Style for the resource title */
.resource-title {
  font-size: 1.4rem;
  color: #333;
  line-height: 1.2;
  font-weight: bolder;
}

.now {
  font-size: 0.9rem;
  color: #FF1454;
  margin-bottom: 0.2rem;
  text-align: left;
  text-decoration: underline;
}
