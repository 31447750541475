#features ul {
    list-style-type: none;
    padding: 0;
  }
  
  #features li {
    margin-bottom: 10px;
  }
  
  #features a {
    color: blue;
    text-decoration: none;
  }

/* Additional formatting */
h1 span {
  font-weight: bold;
  font-size: larger;
  
}

h1 span:first-child {
  color: #1927eb; /* Blue */
}

h1 span:nth-child(2) {
  color: #ff1453; /* Pink */
}

h1 span:nth-child(3) {
  color: #ff9214; /* Orange */
}

h1 span:last-child {
  color: #24d3ff; /* Light Blue */
}